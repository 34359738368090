import {
  IPaginatedRequestOptions,
  IPaginatedResponse,
  QueryParamValue,
  useDjangoPaginatedQuery,
} from '@color/continuum';
import { IErrorPayload } from '@color/lib';
import { merge } from 'lodash';
import path from 'path';
import qs from 'qs';
import { useAuth } from 'react-oidc-context';
import { QueryClient, UseQueryOptions, useQuery } from 'react-query';
import Cookies from 'universal-cookie';

import { config } from '../config';
import { MFA_PAGE_URL } from './constants';
import { convertKeysToCamelCase, convertKeysToSnakeCase } from './format';
import { JsonObject } from './types';

// TODO @rohittalwalkar - reuse from `src/projects/home/frontend/src/lib/util/api/api.ts`
// instead of implementing this again
type Method = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export const OTP_PATH_URL_KEY = 'otpauthUrl';

export interface Options {
  /**
   * By default, get an access token & pass it as an Authorization header.
   *
   * The access token vouches for a user's identity (it's a signed token that
   * includes a claim about the user's email address). That token is required to
   * hit any of our protected API routes.
   */
  accessToken: string;
  searchParams?: Record<string, QueryParamValue>;
}
export type QueryKey = [route: string, options: Options];

interface IApiErrorResponse {
  errors: string[];
}

export type UserInfo = {
  id: number;
  cvl: string;
  permissions: string[];
};

export class ApiError extends Error {
  public errors: string[] | string;

  constructor(errorResponse: string | IApiErrorResponse) {
    super(errorResponse.toString());
    this.name = 'API Response Error';
    if (typeof errorResponse === 'string') {
      this.errors = errorResponse;
    } else {
      this.errors = errorResponse.errors;
    }
  }
}

export interface AcceptChallengeApiPayload {
  updatedReportsCount: number;
}

const buildQueryString = (searchParams: Record<string, QueryParamValue> = {}) =>
  qs.stringify(convertKeysToSnakeCase(searchParams as JsonObject), { arrayFormat: 'repeat' });

const RedirectToMfa = (otpauthUrl: string) => {
  window.sessionStorage.setItem(OTP_PATH_URL_KEY, otpauthUrl);
  if (window.location.pathname !== MFA_PAGE_URL) {
    window.location.href = MFA_PAGE_URL;
  }
  throw new ApiError(`MFA required. Redirecting to '${MFA_PAGE_URL}'`);
};

export const OTP_REQUIRED_MESSAGE = 'OTP input required';

const isMfaMissingError = (errorResponse: any) => {
  return (
    (errorResponse.message?.startsWith('otpauth://totp') && errorResponse.code === 401) ||
    (errorResponse.message === OTP_REQUIRED_MESSAGE && errorResponse.code === 401)
  );
};

const handleErrorResponse = (errorResponse: any) => {
  if (typeof errorResponse === 'string') {
    throw new ApiError(errorResponse);
  }
  if (typeof errorResponse?.message === 'string') {
    throw new ApiError(errorResponse?.message);
  }
  if (errorResponse.errors && errorResponse.errors.length > 0) {
    throw new ApiError(errorResponse.errors.join(', '));
  }
  throw new ApiError(JSON.stringify(errorResponse));
};

export async function fetchWithAuth(
  url: string,
  accessToken: string,
  method = 'GET' as Method,
  additionalFetchParams = {},
  additionalHeaders = {}
): Promise<JsonObject> {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
    ...additionalHeaders,
  };

  // TODO:
  // - Consider using a more user-friendly HTTP client than `fetch`
  //   See what we do in LIMS (using the `ky` library):
  //   https://github.com/color/lims/blob/master/src/projects/home/frontend/src/api/lims.ts
  // - Catch exceptions, throwing them with an error type
  // - polyfill `fetch` if we need to support IE11 on RHP
  const resp = await fetch(url, {
    method,
    headers,
    ...additionalFetchParams,
  });

  // check for 401 and otpauth in message and redirect to /mfa if present
  if (resp.status === 401) {
    const errorResponse = await resp.json();
    if (isMfaMissingError(errorResponse)) {
      // the message will contain the otpauth that we'll display to the user in the MFA flow
      RedirectToMfa(errorResponse.message);
    } else {
      handleErrorResponse(errorResponse);
    }
  }
  if (resp.ok === false) {
    const errorResponse = await resp.json();
    handleErrorResponse(errorResponse);
  }

  if (resp.status === 204) {
    // 204 = No Content so resp.json() will throw an exception
    return {};
  }

  const responseData = await resp.json();
  if (responseData.errors && responseData.errors.length > 0) {
    throw new ApiError(responseData.errors.join(', '));
  }

  return convertKeysToCamelCase(responseData);
}

async function defaultQueryFn<T extends JsonObject>(args: {
  queryKey: readonly unknown[];
}): Promise<IPaginatedResponse<T>> {
  const { queryKey } = args as { queryKey: QueryKey };
  const [route, { accessToken, searchParams }] = queryKey;

  // Form URL for API call from API base URL and route param
  const urlBase = path.join(config.API_ROOT, route);
  const url = `${urlBase}?${buildQueryString(searchParams ?? {})}`;

  // The token-fetching callbacks from @auth0/auth0-react have an optional args object.
  // Downcasting to an argumentless func that returns a promised string makes an easier interface:
  // - Lets us use one of several functions to get access tokens.
  // - Lets us specify defaults at the provider level, not here.
  //
  // If not downcasting, alternate valid type annotations for `getAccessTokenSilently`:
  // - Auth0ContextInterface['getAccessTokenSilently']
  // - (options?: GetTokenSilentlyOptions) => Promise<string>
  const result = await fetchWithAuth(url, accessToken);
  return (result as unknown) as IPaginatedResponse<T>;
}

export const useColorQuery = <T = JsonObject>(
  route: string,
  options: Omit<Options, 'accessToken'> = {},
  queryKey: number = 0
) => {
  const auth = useAuth();
  const queryResult = useQuery<T, IErrorPayload>([
    route,
    { ...options, accessToken: auth.user?.id_token },
    queryKey,
  ]);

  // The MFA error has a different response shape
  const couldBeMfaError = (queryResult as any) as { message?: string; code: number };
  if (couldBeMfaError.message && isMfaMissingError(couldBeMfaError)) {
    RedirectToMfa(couldBeMfaError.message);
  }
  return queryResult;
};

export const useColorPaginatedQuery = <T = JsonObject>(
  route: string,
  requestOptions?: IPaginatedRequestOptions,
  queryOptions?: UseQueryOptions<
    IPaginatedResponse<T>,
    IErrorPayload,
    IPaginatedResponse<T>,
    QueryKey
  >
) => {
  const auth = useAuth();
  const requestOptionsWithAuth = merge({}, requestOptions, {
    queryArgs: { accessToken: auth.user?.id_token },
  });
  const queryResult = useDjangoPaginatedQuery<T>(
    route,
    requestOptionsWithAuth,
    queryOptions as Record<string, unknown>
  );
  return queryResult;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      refetchOnWindowFocus: false,
    },
  },
});

const useMethodWithAuth = (httpMethod: 'PATCH' | 'POST') => {
  const auth = useAuth();
  const globalCookies = new Cookies();

  return (route: string, payload: any) => {
    const url = path.join(config.API_ROOT, route);

    return fetchWithAuth(
      url,
      auth.user!.id_token!,
      httpMethod,
      { body: JSON.stringify(payload) },
      { 'X-CSRFToken': globalCookies.get('csrftoken') }
    );
  };
};

export const usePatchWithAuth = () => {
  return useMethodWithAuth('PATCH');
};

export const usePostWithAuth = () => {
  return useMethodWithAuth('POST');
};
