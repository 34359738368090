import { Box, Grid, Text, Title } from '@color/continuum';

import { SignificantGene } from 'lib/types';
import { formatOrdinal, getReportSignificanceText } from 'lib/utils';

export interface Props {
  sampleId: string;
  sex: string;
  age: string;
  biobankId: string;
  testType: string;
  significantGenes: SignificantGene[] | null;
  revisionNumber: number;
  reportId: string;
}

export const HeaderReportInfo: React.FC<Props> = (props) => {
  const {
    sampleId,
    sex,
    age,
    biobankId,
    testType,
    significantGenes,
    revisionNumber,
    reportId,
  } = props;
  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={4}>
        <Box>
          <Text bold>Report Id</Text>
        </Box>
        <Box>
          <Title variant="headline1">{reportId}</Title>
        </Box>
        <Box>
          <Text>{`${sex}, ${age} years old | Sample ID ${sampleId} | Biobank ID ${biobankId}`}</Text>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box>
          <Text bold>Test type</Text>
        </Box>
        <Box>
          <Text>{testType}</Text>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box>
          <Text bold>Clinical Significance</Text>
        </Box>
        <Box>
          {getReportSignificanceText(testType, significantGenes).map((significanceText) => (
            <Text key={significanceText}>{significanceText}</Text>
          ))}
        </Box>
      </Grid>
      <Grid item>
        <Box>
          <Text bold>Report revision</Text>
        </Box>
        <Box>
          <Text>{formatOrdinal(revisionNumber + 1)}</Text>
        </Box>
      </Grid>
    </Grid>
  );
};
