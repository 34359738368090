import {
  Box,
  Button,
  Divider,
  Grid,
  InlineSpacer,
  Spacer,
  Text,
  Title,
  useToast,
} from '@color/continuum';
import { capitalize } from 'lodash';
import { useState } from 'react';

import { AlertCard } from 'components/common/AlertCard';
import { usePostWithAuth } from 'lib/api';
import { DisplayDifference } from 'lib/types';

import { AcceptDialog } from './AcceptDialog';
import { ChallengeDialog } from './ChallengeDialog';

interface Props {
  differences: Array<DisplayDifference>;
  variantId: number;
  setShowOnHoldAccept: (showCard: boolean) => void;
  setShowOnHoldChallenge: (showCard: boolean) => void;
}

export const AcceptChallengeCard: React.FC<Props> = (props) => {
  const { differences, setShowOnHoldAccept, setShowOnHoldChallenge, variantId } = props;
  const [showAccept, setShowAccept] = useState(false);
  const [showChallenge, setShowChallenge] = useState(false);
  const { showDeprecatedErrorToast: showErrorToast } = useToast();
  const postWithAuth = usePostWithAuth();

  return (
    <AlertCard
      alertSeverity="error"
      alertTitle="Action required: Review discordant variant interpretation"
    >
      <Grid container>
        <Grid item />
        {differences.map((diff) => (
          <Box key={diff.caption}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Text>{capitalize(diff.caption)}</Text>
            </Box>
            <Spacer variant="small" />
            <Title variant="headline3">
              <i>All of Us</i> {diff.caption}: {diff.aou}
            </Title>
            <Title variant="headline3">
              Your lab&apos;s {diff.caption}: {diff.lab}
            </Title>
            <Spacer variant="small" />
            <Divider aria-hidden="true" />
            <Spacer variant="small" />
          </Box>
        ))}
        <Grid item>
          <Text variant="body1">
            Your lab&apos;s variant interpretation of this variant is different than the existing{' '}
            <i>All of Us</i> variant interpretation. You can either accept the <i>All of Us</i>{' '}
            variant interpretation or challenge the <i>All of Us</i> variant interpretation to stand
            by your lab&apos;s decision.
          </Text>
        </Grid>
        <Spacer variant="small" />
        <Grid item>
          <Button variant="color-primary" onClick={() => setShowAccept(true)}>
            Accept
          </Button>
          <InlineSpacer variant="small" />
          <Button variant="color-secondary" onClick={() => setShowChallenge(true)}>
            Challenge
          </Button>
        </Grid>
        <AcceptDialog
          isOpen={showAccept}
          differences={differences}
          onAccept={async () => {
            setShowAccept(false);

            try {
              await postWithAuth(`/variants/${variantId}/accept`, {});

              setShowOnHoldAccept(true);
              // show the alert card
            } catch (errors: any) {
              showErrorToast('We were not able to accept the All of Us classification.');
              // TODO @rohittalwalkar - log errors somewhere or show them to the user
            }
          }}
          onCancel={() => setShowAccept(false)}
        />
      </Grid>
      <ChallengeDialog
        isOpen={showChallenge}
        differences={differences}
        onAccept={async () => {
          setShowChallenge(false);
          try {
            await postWithAuth(`/variants/${variantId}/challenge`, {});

            setShowOnHoldChallenge(true);
          } catch (errors: any) {
            showErrorToast('Could not challenge the variant interpretation.');
            // TODO @rohittalwalkar - log errors somewhere or show them to the user
          }
        }}
        onCancel={() => setShowChallenge(false)}
      />
    </AlertCard>
  );
};
