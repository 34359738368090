import { ActionLink, Box, Grid, MuiTextField, MultipleSelect, Text } from '@color/continuum';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Classification, ClassificationKey, Status, StatusKey } from 'lib/constants';

const statusOptions = (Object.keys(Status) as Array<keyof typeof Status>).map((key) => ({
  key,
  value: Status[key].fullName,
}));

const classificationOptions = (Object.keys(Classification) as Array<
  keyof typeof Classification
>).map((key) => ({
  key,
  value: Classification[key].displayName,
}));

export interface VariantsFilterProps {
  gene: string;
  setGene: (gene: string) => void;
  variant: string;
  setVariant: (variang: string) => void;
  selectedStatuses: Set<StatusKey>;
  setSelectedStatuses: (selected: Set<StatusKey>) => void;
  selectedClassifications: Set<ClassificationKey>;
  setSelectedClassifications: (selected: Set<ClassificationKey>) => void;
}

export const VariantsFilter: React.FC<VariantsFilterProps> = (props) => {
  const flags = useFlags();
  const {
    gene,
    setGene,
    variant,
    setVariant,
    selectedStatuses,
    setSelectedStatuses,
    selectedClassifications,
    setSelectedClassifications,
  } = props;
  const resetFilters = () => {
    setGene('');
    setVariant('');
    setSelectedStatuses(new Set());
    setSelectedClassifications(new Set());
  };

  return (
    <Box>
      <Grid container spacing={2} justifyContent="space-evenly">
        <Grid item xs={6}>
          <Text bold>Search By</Text>
        </Grid>
        <Grid item xs={1}>
          <Text bold>Filter By</Text>
        </Grid>
        <Grid item xs={5} sx={{ textAlign: 'right' }}>
          <ActionLink onClick={resetFilters}>Reset Filters</ActionLink>
        </Grid>
        <Grid item xs={2}>
          <MuiTextField
            type="search"
            value={gene}
            onChange={(event) => setGene((event.target as HTMLInputElement).value)}
            InputProps={{
              placeholder: 'Gene',
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <MuiTextField
            type="search"
            value={variant}
            onChange={(event) => setVariant((event.target as HTMLInputElement).value)}
            InputProps={{
              placeholder: 'Variant',
            }}
            fullWidth
          />
        </Grid>
        <Grid item container xs={6} spacing={2} justifyContent="space-evenly">
          <Grid item xs={6}>
            <MultipleSelect
              label={selectedClassifications.size === 0 ? 'Classification' : undefined}
              options={classificationOptions}
              selected={selectedClassifications}
              setSelected={(classifications) =>
                setSelectedClassifications(classifications as Set<ClassificationKey>)
              }
              optionalIndicatorIsEnabled={false}
            />
          </Grid>
          <Grid item xs={6}>
            {flags && flags.showVariantStatusFilter && (
              <MultipleSelect
                label={selectedStatuses.size === 0 ? 'Status' : undefined}
                options={statusOptions}
                selected={selectedStatuses}
                setSelected={(statuses) => setSelectedStatuses(statuses as Set<StatusKey>)}
                optionalIndicatorIsEnabled={false}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
