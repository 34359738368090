import {
  Column,
  DjangoTableWithPagination,
  IPaginatedResponse,
  SortDirections,
  TableBody,
  TableCell,
  TableRow,
  Text,
} from '@color/continuum';
import { camelCase, mapKeys } from 'lodash';
import { useState } from 'react';

import { DisplayError } from 'components/DisplayError';
import { remapReportStatus } from 'components/Reports/ReportsList';
import { useColorPaginatedQuery } from 'lib/api';
import { REPORT_STATUS_NAMES, Report, ReportStatus } from 'lib/types';
import { formatDateString, openReportInNewTab } from 'lib/utils';

const columns: Column[] = [
  {
    id: 'lastUpdated',
    name: 'Last updated',
    isSortable: false,
  },
  {
    id: 'reportId',
    name: 'Report ID',
    isSortable: false,
  },
  {
    id: 'sampleId',
    name: 'Sample ID',
    isSortable: false,
  },
  {
    id: 'biobankId',
    name: 'Biobank ID',
    isSortable: false,
  },
  {
    id: 'cvl',
    name: 'CVL',
    isSortable: false,
  },
  {
    id: 'status',
    name: 'Status',
    isSortable: false,
  },
];

interface VariantReportsProps {
  variantId: number;
  enableLinks: boolean;
}

export const VariantReports: React.FC<VariantReportsProps> = (props) => {
  const { variantId, enableLinks } = props;
  const {
    isLoading,
    isError,
    error,
    data: paginatedData,
    tableOptions,
    paginationOptions,
  } = useColorPaginatedQuery<Report>(`/variants/${variantId}/reports`);

  const [sortDirections] = useState<SortDirections>({});

  const { setSortDirections } = tableOptions;

  if (isError) {
    return <DisplayError {...error} />;
  }
  if (isLoading) {
    return <Text>Loading...</Text>;
  }
  if (!paginatedData?.results.length) {
    return <Text>There are no reports with this variant.</Text>;
  }
  const djangoResponse: IPaginatedResponse<Report> = {
    ...paginatedData,
    results: (paginatedData!.results.map((rawVariant) =>
      mapKeys(rawVariant, (value, key) => camelCase(key))
    ) as unknown) as Report[],
  };
  const tableProps = {
    isLoading,
    tableOptions: { sortDirections, setSortDirections },
    paginationOptions,
    djangoResponse,
  };
  return (
    <DjangoTableWithPagination {...{ columns, ...tableProps }}>
      <TableBody>
        {djangoResponse.results.map((report) => {
          const status = remapReportStatus(report).status || '';
          return (
            <TableRow
              hover
              key={report.id}
              onClick={() => {
                if (!enableLinks) {
                  return;
                }
                openReportInNewTab(report.id, report.analysisType);
              }}
              sx={{ cursor: enableLinks ? 'pointer' : 'none' }}
            >
              <TableCell>
                <Text>{report.updatedAt ? formatDateString(report.updatedAt) : ''}</Text>
              </TableCell>
              <TableCell>
                <Text>{report.id}</Text>
              </TableCell>
              <TableCell>
                <Text>{report.sampleId}</Text>
              </TableCell>
              <TableCell>
                <Text>{report.biobankId}</Text>
              </TableCell>
              <TableCell>
                {/* TODO *rohittalwalkar: display cvl's fullname  */}
                <Text>{report.cvl}</Text>
              </TableCell>
              <TableCell>
                <Text>{REPORT_STATUS_NAMES[status as ReportStatus] || status}</Text>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </DjangoTableWithPagination>
  );
};
