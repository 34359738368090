import { Box, Container, FormikSelectField, FormikTextField, Spacer, Text } from '@color/continuum';
import { camelCase } from 'lodash';

import {
  AssociatedDisorders,
  CvlClassification,
  HGVS_FIELDS,
  REQUIRED_VAC_FIELDS,
  VariantFieldToDisplayCaption,
} from 'lib/constants';
import { CvlInterpretation, Variant } from 'lib/types';
import { formatDateString, getCvlData, isFieldDiscordant } from 'lib/utils';

const FIELDS_WITH_OPTIONS = {
  classification: (Object.keys(CvlClassification) as (keyof typeof CvlClassification)[]).map(
    (key) => ({
      label: CvlClassification[key].displayName,
      value: CvlClassification[key].internalName,
    })
  ),
  disorder: (Object.keys(AssociatedDisorders) as (keyof typeof AssociatedDisorders)[]).map(
    (key) => ({
      label: `${key} (${AssociatedDisorders[key]})`,
      value: AssociatedDisorders[key],
    })
  ),
  lowPenetrance: [
    { label: 'True', value: 'True' },
    { label: 'False', value: 'False' },
  ],
} as { [key: string]: Array<{ label: string; value: string }> };

interface FormItemProps {
  field: string;
  position: number;
  showLabel: boolean;
  variant: Variant;
  challengeDate: string;
  cvl: string;
}
const FormItem: React.FC<FormItemProps> = (props) => {
  const { field, position, showLabel, variant, challengeDate, cvl } = props;
  const caption = VariantFieldToDisplayCaption[
    field as keyof typeof VariantFieldToDisplayCaption
  ] as string;
  const cvlData = getCvlData(cvl, variant.cvlInterpretations);
  const isDiscordant = isFieldDiscordant(variant, cvlData, camelCase(field), variant.isReportable);
  const fieldStatus = isDiscordant ? 'Conflicting' : 'Non-conflicting';

  return (
    <Box>
      {showLabel && (
        <Text>
          <b>{`${position}. ${caption}`}</b>
        </Text>
      )}
      <Text>{`Current ${caption}: ${variant[camelCase(field) as keyof Variant]}`}</Text>
      <Text>{`${fieldStatus} ${caption}: ${
        cvlData[camelCase(field) as keyof CvlInterpretation]
      } submitted on ${formatDateString(challengeDate)}`}</Text>
      <Text>{`Resolved ${caption}`}</Text>
      <Spacer variant="large" />
      {!Object.keys(FIELDS_WITH_OPTIONS).includes(field) && (
        <FormikTextField
          label={field}
          name={field}
          required={REQUIRED_VAC_FIELDS.includes(field as typeof REQUIRED_VAC_FIELDS[number])}
          requiredIndicatorIsEnabled
          type="text"
        />
      )}
      {Object.keys(FIELDS_WITH_OPTIONS).includes(field) && (
        <FormikSelectField
          name={field}
          label={field}
          required={REQUIRED_VAC_FIELDS.includes(field as typeof REQUIRED_VAC_FIELDS[number])}
          options={FIELDS_WITH_OPTIONS[field].map((option) => {
            return { value: option.value, label: option.label };
          })}
        />
      )}
    </Box>
  );
};

interface NomenclatureProps {
  field: string;
  position: number;
  challengeDate: string;
  variant: Variant;
  cvl: string;
}

const NomenclatureFields: React.FC<NomenclatureProps> = (props) => {
  const { field, position, variant, challengeDate, cvl } = props;

  return (
    <>
      <Text>
        <b>{`${position}. Nomenclature`}</b>
      </Text>
      {field.split(',').map((fieldName) => (
        <Container key={fieldName} sx={{ paddingLeft: 0, paddingRight: 0 }}>
          <Spacer variant="small" />
          <FormItem
            showLabel={false}
            field={fieldName}
            position={position}
            variant={variant}
            challengeDate={challengeDate}
            cvl={cvl}
          />
        </Container>
      ))}
    </>
  );
};

interface ResolveFormItems {
  field: string;
  position: number;
  challengeDate: string;
  variant: Variant;
  cvl: string;
}

/**
 * We are grouping all the nomenclature fields
 * and passing them in as a comma deliniated string
 *
 * Those are the only fields which will contain a comma
 */
export const isSingleOrGroupedHgvs = (field: string) =>
  field.includes(',') || HGVS_FIELDS.has(field);

export const ResolveFormItems: React.FC<ResolveFormItems> = (props) => {
  const { field } = props;

  if (isSingleOrGroupedHgvs(field)) {
    return <NomenclatureFields {...props} />;
  }
  return <FormItem {...props} showLabel />;
};
