import {
  IPaginatedResponse,
  PageContainer,
  SortDirections,
  Spacer,
  Text,
  Title,
  useThrottle,
} from '@color/continuum';
import { camelCase, mapKeys } from 'lodash';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { DisplayError } from 'components/DisplayError';
import { useColorPaginatedQuery } from 'lib/api';
import { Classification, ClassificationKey, Status, StatusKey } from 'lib/constants';
import { convertKeysToSnakeCase } from 'lib/format';
import { Variant } from 'lib/types';

import { VariantsFilter } from './VariantsFilter';
import { VariantsTable } from './VariantsTable';

export const VariantsList: React.FC = () => {
  const [gene, setGene] = useState<string>('');
  const [variant, setVariant] = useState<string>('');
  const [selectedStatuses, setSelectedStatuses] = useState<Set<StatusKey>>(new Set());
  const [selectedClassifications, setSelectedClassifications] = useState<Set<ClassificationKey>>(
    new Set()
  );

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  if (!selectedStatuses.has('Challenged') && searchParams.get('filter') === 'challenged') {
    setSelectedStatuses(new Set(['Challenged', ...selectedStatuses]));
  }
  const filterProps = {
    gene,
    setGene,
    variant,
    setVariant,
    selectedStatuses,
    setSelectedStatuses,
    selectedClassifications,
    setSelectedClassifications,
  };
  const throttledGene = useThrottle(gene, 300);
  const throttledVariant = useThrottle(variant, 300);

  const {
    isLoading,
    isError,
    error,
    data,
    tableOptions,
    paginationOptions,
  } = useColorPaginatedQuery<Variant>('/variants', {
    additionalQueryParams: {
      classification: Array.from(selectedClassifications).map(
        (classification) => Classification[classification].internalName
      ),
      status: [...selectedStatuses].map((statusKey: StatusKey) => Status[statusKey].slug),
      search: throttledGene,
      variant: throttledVariant,
    },
  });

  const [sortDirections, setTableSortDirections] = useState<SortDirections>({});

  const { setSortDirections: setDjangoSortDirections } = tableOptions;

  /**
   * Map the table sort directions to the backend sort directions. Namely, "variant" is
   * not a field in the backend, so when we sort by "variant" on the frontend we
   * actually want to sort by cHGVS and pHGVS on the backend.
   * */
  const setSortDirections = (newSortDirections: SortDirections) => {
    setTableSortDirections(newSortDirections);
    const djangoSortDirections = convertKeysToSnakeCase(newSortDirections);
    if ('variant' in djangoSortDirections) {
      djangoSortDirections.cHGVS = djangoSortDirections.variant;
      djangoSortDirections.pHGVS = djangoSortDirections.variant;
      delete djangoSortDirections.variant;
    }
    setDjangoSortDirections(djangoSortDirections);
  };

  let variantsList = <Text />;
  if (isError) {
    variantsList = <DisplayError {...error} />;
  } else if (isLoading) {
    variantsList = <Text>Loading...</Text>;
  } else if (!data?.results.length) {
    variantsList = (
      <>
        <VariantsFilter {...filterProps} />
        <Text>No variants to show</Text>
      </>
    );
  } else {
    const djangoResponse: IPaginatedResponse<Variant> = {
      ...data,
      results: (data!.results.map((rawVariant) =>
        mapKeys(rawVariant, (value, key) => camelCase(key))
      ) as unknown) as Variant[],
    };
    const tableProps = {
      isLoading,
      tableOptions: { sortDirections, setSortDirections },
      paginationOptions,
      djangoResponse,
    };
    variantsList = (
      <>
        <VariantsFilter {...filterProps} />
        <Spacer variant="xLarge" />
        <VariantsTable {...tableProps} />
      </>
    );
  }

  return (
    <PageContainer helmetTitle="Variants">
      {/* TODO: remove this extra spacer when the Appbar is migrated to shared Appbar component (https://getcolor.atlassian.net/browse/PC-162) */}
      <Spacer variant="xxLarge" />
      <Title>Variants</Title>
      <Spacer variant="large" />
      {variantsList}
    </PageContainer>
  );
};
