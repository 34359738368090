import {
  Box,
  Column,
  DjangoTableWithPagination,
  IPaginatedResponse,
  SortDirections,
  TableBody,
  TableCell,
  TableRow,
  Text,
  Title,
} from '@color/continuum';
import { camelCase, mapKeys } from 'lodash';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { DisplayError } from 'components/DisplayError';
import { getVariantNomenclature } from 'components/Variants/util';
import { UserInfo, useColorPaginatedQuery, useColorQuery } from 'lib/api';
import { Variant } from 'lib/types';
import { getCvlData } from 'lib/utils';

export const variantTableColumns: Column[] = [
  {
    id: 'gene',
    name: 'Gene',
    isSortable: false,
  },
  {
    id: 'variant',
    name: 'Variant',
    isSortable: false,
  },
  {
    id: 'condition',
    name: 'Condition',
    isSortable: false,
  },
  {
    id: 'classification',
    name: 'Classification',
    isSortable: false,
  },
  {
    id: 'zygosity',
    name: 'Zygosity',
    isSortable: false,
  },
  {
    id: 'date',
    name: 'Date Classified',
    isSortable: false,
  },
];

interface VariantTableBodyProps {
  variants: Variant[];
}
export const VariantTableBody: React.FC<VariantTableBodyProps> = (props) => {
  const { variants } = props;
  const { isLoading, isError, error, data } = useColorQuery<UserInfo>(`/userinfo`);
  const history = useHistory();

  if (isError) {
    return <Text>{`${error}`}</Text>;
  }
  if (isLoading) {
    return <Text>Loading...</Text>;
  }
  // @rohittalwalkar - useColorQuery might return "undefined"
  if (data === undefined) {
    return <Text>Loading...</Text>;
  }

  return (
    <TableBody>
      {variants.map((variant: Variant) => {
        const cvlData = getCvlData(data.cvl, variant.cvlInterpretations, '');
        return (
          <TableRow
            key={variant.id}
            hover
            onClick={() => history.push(`/variants/${variant.id}`)}
            sx={{ cursor: 'pointer' }}
          >
            <TableCell>
              <Text>{variant.gene}</Text>
            </TableCell>
            <TableCell>
              <Text>{getVariantNomenclature(variant)}</Text>
            </TableCell>
            <TableCell>
              <Text>{cvlData.disorder}</Text>
            </TableCell>
            <TableCell>
              <Text>{cvlData.classification}</Text>
            </TableCell>
            <TableCell>
              <Text>{variant.zygosity}</Text>
            </TableCell>
            <TableCell>
              <Text>{cvlData.createdAt}</Text>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};
export const NonReportableVariantsTable: React.FC = () => {
  const { id: reportId } = useParams<{ id: string }>();

  const {
    isLoading,
    isError,
    error,
    data: paginatedData,
    tableOptions,
    paginationOptions,
  } = useColorPaginatedQuery<Variant>(`/reports/${reportId}/variants/nonreportable`);

  const [sortDirections] = useState<SortDirections>({});

  const { setSortDirections } = tableOptions;

  let variantsList = <Text />;
  if (isError) {
    variantsList = <DisplayError {...error} />;
  } else if (isLoading) {
    variantsList = <Text>Loading...</Text>;
  } else if (!paginatedData?.results.length) {
    variantsList = <Text>There are no non-informative variants.</Text>;
  } else {
    const djangoResponse: IPaginatedResponse<Variant> = {
      ...paginatedData,
      results: (paginatedData!.results.map((rawVariant) =>
        mapKeys(rawVariant, (value, key) => camelCase(key))
      ) as unknown) as Variant[],
    };
    const paginationProps = { rowsPerPageOptions: [50, 100, 500] };
    const tableProps = {
      isLoading,
      tableOptions: { sortDirections, setSortDirections },
      paginationOptions: { ...paginationOptions, ...{ rowsPerPage: 50 } },
      djangoResponse,
      paginationProps,
    };
    variantsList = (
      <DjangoTableWithPagination {...{ columns: variantTableColumns, ...tableProps }}>
        <VariantTableBody variants={djangoResponse.results} />
      </DjangoTableWithPagination>
    );
  }
  return (
    <Box>
      <Title>Non-reportable Variants</Title>
      {variantsList}
    </Box>
  );
};
