import {
  Box,
  Container,
  InternalButtonLink,
  PageContainer,
  Spacer,
  Text,
  useToast,
} from '@color/continuum';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FormikHelpers } from 'formik';
import { useHistory, useParams } from 'react-router-dom';

import { useColorQuery, usePostWithAuth } from 'lib/api';
import { Variant } from 'lib/types';

import { getVariantTitle } from '../util';
import { ResolveForm } from './ResolveForm';

export const ResolvePage: React.FC = () => {
  const history = useHistory();
  const { id: variantId } = useParams<{ id: string }>();
  const { isLoading, isError, error, data: variant } = useColorQuery<Variant>(
    `/variants/${variantId}`
  );
  const { showDeprecatedErrorToast } = useToast();
  const postWithAuth = usePostWithAuth();
  if (isError) {
    return <Text>{`${error}`}</Text>;
  }
  if (isLoading || variant === undefined) {
    return <Text>Loading...</Text>;
  }

  return (
    <PageContainer helmetTitle="Resolve Variant">
      {/* TODO: remove this extra spacer when the Appbar is migrated to shared Appbar component (https://getcolor.atlassian.net/browse/PC-162) */}
      <Spacer variant="xxLarge" />
      <Container>
        <Box height="48px">
          <InternalButtonLink
            to={`/variants/${variantId}`}
            startIcon={<ArrowBackIcon />}
            sx={{
              background: 'none',
              minHeight: '48px',
              padding: 0,
              textDecoration: 'none',
              '&:hover': {
                background: 'none',
              },
            }}
          >
            Back
          </InternalButtonLink>
        </Box>
        <ResolveForm
          variant={variant}
          variantTitle={getVariantTitle(variant)}
          fieldsToResolve={variant.challengeInfo.fields}
          onSubmit={async (values: any, { setSubmitting }: FormikHelpers<any>) => {
            try {
              await postWithAuth(`/variants/${variantId}/resolve`, { ...values });
              history.push(`/variants/${variantId}`);
            } catch (errors: any) {
              showDeprecatedErrorToast('We were not able to save the updated classification.');
              // TODO @rohittalwalkar - log errors somewhere or show them to the user
            } finally {
              setSubmitting(false);
            }
          }}
        />
      </Container>
    </PageContainer>
  );
};
