import {
  Box,
  Button,
  IPaginatedResponse,
  SortDirections,
  Spacer,
  Text,
  Title,
  useToast,
} from '@color/continuum';
import { GridRowId } from '@mui/x-data-grid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import pluralize from 'pluralize';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { NavTabs } from 'components/common/NavTabs/NavTabs';
import { DisplayError } from 'components/DisplayError';
import { ReportsTable } from 'components/Reports/ReportsTable/ReportsTable';
import { useColorPaginatedQuery, usePatchWithAuth } from 'lib/api';
import { TestTypes } from 'lib/constants';
import { Report } from 'lib/types';

import { BulkSignoutDialog } from './BulkSignoutDialog';
import { ReportsFilters } from './ReportsFilters';

type ReportStatusCounts = {
  onHoldCount: Number;
  readyForBulkSignOutCount: Number;
  readyForSignOutCount: Number;
  signedOutCount: Number;
  awaitingHrdf: Number;
  totalReports: Number;
};
type ReportDataWithCounts = IPaginatedResponse<Report> & ReportStatusCounts;

const hashToQueryParams = {
  all: { reportStatus: '' },
  bulk_sign_out: { reportStatus: 'ready_for_bulk_sign_out' },
  on_hold: { reportStatus: 'pending' },
  signed_out: { reportStatus: 'released' },
  sign_out: { reportStatus: 'ready_for_sign_out' },
  awaiting_hrdf: { reportStatus: 'awaiting_hrdf' },
};

export const remapReportStatus = (remapReport: Report) => {
  if (remapReport.state === 'automatic_hold') {
    let status = 'Address discordances';
    if (remapReport.hasUnaddressedFileRequest) {
      status = 'Awaiting HRDF';
    } else if (remapReport.hasUnresolvedChallenge) {
      status = 'Awaiting VAC';
    }
    return { ...remapReport, ...{ status } };
  }
  if (remapReport.state === 'manual_hold') {
    return { ...remapReport, ...{ status: 'Manual hold' } };
  }
  if (remapReport.state === 'signed_out') {
    return { ...remapReport, ...{ status: 'Signed out' } };
  }
  // @rohittalwalkar - the other items map correctly via REPORT_STATUS_NAMES
  return remapReport;
};
export const ReportsList = () => {
  const [search, setSearch] = useState<string>('');
  const [selectedTestTypes, setSelectedTestTypes] = useState<Set<TestTypes>>(new Set());
  const { hash } = useLocation();
  const { rhpDashboardImprovements } = useFlags();
  let updatedHash = hash;
  if (updatedHash === '') {
    updatedHash = '#sign_out';
  }
  const { reportStatus } = hashToQueryParams[
    updatedHash.replace('#', '') as keyof typeof hashToQueryParams
  ] || {
    reportStatus: '',
  };
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);

  const {
    isLoading,
    isError,
    error,
    data,
    paginationOptions,
    isFetching,
    refetch,
  } = useColorPaginatedQuery<Report>('/reports', {
    additionalQueryParams: {
      analysis_type: Array.from(selectedTestTypes),
      search,
      status: reportStatus,
    },
    initialRowsPerPage: 1000,
  });

  const { setPageNumber } = paginationOptions || {};
  const [sortDirections, setSortDirections] = useState<SortDirections>({});

  const [showBulkSignout, setShowBulkSignout] = useState(false);
  const patchWithAuth = usePatchWithAuth();

  const {
    showDeprecatedSuccessToast: showSuccessToast,
    showDeprecatedErrorToast: showErrorToast,
  } = useToast();

  let reportTable = <Text />;
  if (isError) {
    reportTable = <DisplayError {...error} />;
  } else if (isLoading || data === undefined) {
    reportTable = <Text>Loading...</Text>;
  } else {
    const updatedHoldStatus = data.results.map(remapReportStatus);
    const tableProps = {
      isLoading: isFetching,
      tableOptions: { sortDirections, setSortDirections },
      paginationOptions,
      reports: updatedHoldStatus,
      totalReports: data.count,
      selectedIds: selectedRowIds,
      setSelectedIds: setSelectedRowIds,
      allowSelection: hash === '#bulk_sign_out',
    };
    reportTable = <ReportsTable {...tableProps} />;
  }

  const dataWithCounts = data as ReportDataWithCounts;
  const reportCounts = dataWithCounts
    ? {
        onHoldCount: dataWithCounts.onHoldCount,
        readyForBulkSignOutCount: dataWithCounts.readyForBulkSignOutCount,
        readyForSignOutCount: dataWithCounts.readyForSignOutCount,
        signedOutCount: dataWithCounts.signedOutCount,
        awaitingHrdf: dataWithCounts.awaitingHrdf,
        totalReports: dataWithCounts.totalReports,
      }
    : {
        onHoldCount: '-',
        readyForBulkSignOutCount: '-',
        readyForSignOutCount: '-',
        signedOutCount: '-',
        awaitingHrdf: '-',
        totalReports: '-',
      };

  return (
    // TODO @rohittalwalkar - fix the height, make it autosize
    // https://getcolor.atlassian.net/browse/ALLOFUS-1501
    <Box sx={{ height: '500px', padding: 0 }}>
      <Title>Reports</Title>
      <Spacer variant="large" />
      <ReportsFilters {...{ search, setSearch, selectedTestTypes, setSelectedTestTypes }} />
      <Spacer variant="xLarge" />
      <NavTabs
        tabs={[
          {
            label: `Ready for sign out (${reportCounts.readyForSignOutCount})`,
            path: '#sign_out',
          },
          {
            label: `Ready for bulk sign out (${reportCounts.readyForBulkSignOutCount})`,
            path: '#bulk_sign_out',
          },
          { label: `On hold (${reportCounts.onHoldCount})`, path: '#on_hold' },
          ...(rhpDashboardImprovements
            ? [
                {
                  label: `Awaiting HRDF (${reportCounts.awaitingHrdf})`,
                  path: '#awaiting_hrdf',
                },
              ]
            : []),
          { label: `Signed out (${reportCounts.signedOutCount})`, path: '#signed_out' },
          { label: `All (${reportCounts.totalReports})`, path: '#all' },
        ]}
        defaultRoute="#sign_out"
        onChange={() => {
          setPageNumber(0);
        }}
      />
      {selectedRowIds.length > 0 && (
        <Box sx={(theme) => ({ height: theme.spacing(8) })}>
          <Spacer variant="small" />
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Text> Selected {selectedRowIds.length} Reports</Text>
            <Button onClick={() => setShowBulkSignout(true)} variant="color-primary">
              Sign out report(s)
            </Button>
          </Box>
          <Spacer variant="small" />
        </Box>
      )}
      {selectedRowIds.length === 0 && (
        <Box>
          <Spacer variant="xxxLarge" />
        </Box>
      )}
      {reportTable}
      <BulkSignoutDialog
        isOpen={showBulkSignout}
        numberOfReports={selectedRowIds.length}
        onAccept={async () => {
          setShowBulkSignout(false);

          try {
            await patchWithAuth(
              '/reports',
              selectedRowIds.map((reportId) => ({ id: reportId, state: 'signed_out' }))
            );
            showSuccessToast('These reports have been signed out.');
          } catch (errorResponse: any) {
            if (errorResponse.errors) {
              showErrorToast(
                `${errorResponse.errors.length} ${pluralize(
                  'report',
                  errorResponse.errors.length
                )} failed to sign out. Reports may have been manually put on hold.`
              );
              return;
            }
            // TODO @rohittalwalkar - log errors
            showErrorToast('Could not bulk sign out reports.');
          } finally {
            setSelectedRowIds([]);
            refetch();
          }
        }}
        onCancel={() => setShowBulkSignout(false)}
      />
    </Box>
  );
};
