import {
  Box,
  Container,
  Divider,
  Spacer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Text,
  Title,
} from '@color/continuum';
import { useParams } from 'react-router-dom';

import { useColorQuery } from 'lib/api';
import { Allele } from 'lib/types';

const columns = [
  {
    id: 'gene',
    name: 'Gene',
    isSortable: false,
  },
  {
    id: 'diplotype',
    name: 'Diplotype',
    isSortable: false,
  },
  {
    id: 'phenotype',
    name: 'Phenotype',
    isSortable: false,
  },
];

export const AllelesTab = () => {
  const { id: reportId } = useParams<{ id: string }>();
  const { isLoading, isError, error, data: alleles } = useColorQuery<Allele[]>(
    `/reports/${reportId}/alleles`
  );
  if (isError) {
    return <Text>{`${error}`}</Text>;
  }
  if (isLoading) {
    return <Text>Loading...</Text>;
  }
  // @rohittalwalkar - useColorQuery might return "undefined"
  if (alleles === undefined) {
    return <Text>Loading...</Text>;
  }

  return (
    <Container>
      <Spacer variant="xLarge" />
      <Box>
        <Title>PGx Translation Table</Title>
        <Table
          columns={columns}
          isLoading={false}
          tableOptions={{ sortDirections: {}, setSortDirections: () => {} }}
        >
          {alleles.length === 0 && <Text>There are no alleles.</Text>}
          {alleles.length > 0 && (
            <TableBody>
              {alleles.map((allele: Allele) => (
                <TableRow key={allele.id}>
                  <TableCell>
                    <Text>{allele.gene}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{allele.diplotype}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{allele.phenotype}</Text>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        <Spacer variant="medium" />
        <Divider aria-hidden="true" />
        <Spacer variant="medium" />
      </Box>
      <Spacer variant="xxxLarge" />
    </Container>
  );
};
